import { ref, watch } from "vue";
import { Filter } from "./imoveis";

export const search_url = ref<URL>(new URL(location.href))
export const filter = ref<Filter>(new Filter())

const updateFilter = () => {
    const search_params = search_url.value.searchParams
    const modo = search_params.get('modo') as "comprar" | "alugar"

    const tipos = search_params.getAll('tipos[]').map(id => Number(id))
    const bairros = search_params.getAll('bairros[]').map(id => Number(id))
    const cidade = Number(search_params.get('cidade')||'')
    const caracteristicas = search_params.getAll('caracteristicas[]').map(id => Number(id))
    const valor_de = Number((search_params.get('valor_de')||'' as string).replace(/\D+/igm, ''))
    const valor_ate = Number((search_params.get('valor_ate')||'' as string).replace(/\D+/igm, ''))
    const dormitorios = Number(search_params.get('dormitorios') as string)
    const vagas = Number(search_params.get('vagas') as string)
    const suites = Number(search_params.get('suites') as string)
    const empreendimento = search_params.get('empreendimento') as string
    const codigo = search_params.get('codigo') as string
    

    filter.value = {
        ...filter.value,
        modo,
        tipos,
        bairros,
        cidade,
        valor_de,
        valor_ate,
        dormitorios,
        vagas,
        suites,
        caracteristicas,
        empreendimento,
        codigo
    }
}

updateFilter()

$(document).on('submit', '[name=form_busca]', function () {
    const $self = $(this) as JQuery<HTMLFormElement>
    const $modo = $self.find('[name=modo]')
    const $sort = $self.find('[name=sort]')
    const $tipos = $self.find('[name^=tipos]:checked')
    const $cidade = $self.find('[name=cidade] option:selected')    
    const $bairros = $self.find('[name^=bairros]:checked')
    const $valor_de = $self.find('[name=valor_de]')
    const $valor_ate = $self.find('[name=valor_ate]')
    const $codigo = $self.find('[name=codigo]')
    const $dormitorios = $self.find('[name=dormitorios]')
    const $vagas = $self.find('[name=vagas]')
    const $area = $self.find('[name=area]')
    const $suites = $self.find('[name=suites]')
    const $caracteristicas = $self.find('[name^=caracteristicas]')
    const $empreendimento = $self.find('[name=empreendimento]')

    const modo = $modo.val() as "comprar" | "alugar"

    let base_url = new URL(document.baseURI)
    let base_path = base_url.pathname

    let path = `${base_path}${modo}`
    if ($tipos.length != 0) {
        let tipo = $tipos.eq(0).data('slug')
        path = `${path}/${tipo}`
    }
    if ($bairros.length) {
        let bairro = $bairros.eq(0).data('slug')
        path = `${path}/${bairro}`
    }

    const url = search_url.value
    url.searchParams.set('modo', modo)

    url.searchParams.delete('tipos[]')
    $tipos.each(function () {
        const $tipo = $(this) as JQuery<HTMLInputElement>
        url.searchParams.append('tipos[]', $tipo.val() as string)
    })

    url.searchParams.delete('cidade')
    if (Number($cidade.val() || '0') !== 0) {
        url.searchParams.set('cidade', $cidade.val() as string)
    }

    url.searchParams.delete('bairros[]')
    $bairros.each(function () {
        const $bairro = $(this) as JQuery<HTMLInputElement>
        url.searchParams.append('bairros[]', $bairro.val() as string)
    })

    url.searchParams.delete('valor_de')
    if ($valor_de.val()) {
        url.searchParams.set('valor_de', $valor_de.val() as string)
    }

    url.searchParams.delete('valor_ate')
    if ($valor_ate.val()) {
        url.searchParams.set('valor_ate', $valor_ate.val() as string)
    }

    url.searchParams.delete('codigo')
    if ($codigo.val()) {
        url.searchParams.set('codigo', $codigo.val() as string)
    }

    url.searchParams.delete('dormitorios')
    if ($dormitorios.val()) {
        url.searchParams.set('dormitorios', $dormitorios.val() as string)
    }

    url.searchParams.delete('vagas')
    if ($vagas.val()) {
        url.searchParams.set('vagas', $vagas.val() as string)
    }

    url.searchParams.delete('area')
    if ($area.val()) {
        url.searchParams.set('area', $area.val() as string)
    }

    url.searchParams.delete('suites')
    if ($suites.val()) {
        url.searchParams.set('suites', $suites.val() as string)
    }

    url.searchParams.delete('caracteristicas[]')
    if ($caracteristicas.val() && ($caracteristicas.val() as (number | string)[]).length != 0) {
        for (let caracteristica_id of ($caracteristicas.val() as string[])) {
            url.searchParams.append('caracteristicas[]', caracteristica_id)
        }
    }

    if ($sort) {
        url.searchParams.set('sort', $sort.val() as string)
    }

    url.searchParams.delete('empreendimento')
    if ($empreendimento.val()) {
        url.searchParams.set('empreendimento', $empreendimento.val() as string)
    }

    const pathname = url.pathname
    url.pathname = path

    
    
    let regexp = new RegExp(`^${base_path}`)
    if (['/', '/home', '/home/', ''].includes(pathname.replace(regexp, ''))) {
        
        location.href = url.toString()
    } else {
        history.replaceState({}, '', url)
        search_url.value = url

        updateFilter()
        
        
    }


    return false
})

$(document).on('change', '[name=modo], [name=valor_de], [name=valor_ate], [name=dormitorios], [name=suites], [name=vagas], [name=area], [name^=caracteristicas]', function () {
    const url = search_url.value
    const pathname = url.pathname


    if (!['/', '/home', '/home/', ''].includes(pathname)) {
        $('[name=form_busca]').trigger('submit')
    }
})
$(document).on('updated', '[name=form_busca]', function () {
    $('[name=form_busca]').trigger('submit')
})