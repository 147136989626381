<template>
    <ul class="col-12 pg">
        <li class="prev" v-if="pagination.page > 1">
            <a href="javascript:void(0)" @click="() => setPage(pagination.page-1)">&lt;</a>
        </li>
        <li v-if="first_page > 2">
            <a href="javascript:void(0)" @click="() => setPage(1)">
                <span>
                    1
                </span>
            </a>
        </li>
        <li v-if="first_page > 2">
            <a href="javascript:void(0)"><span>...</span></a>
        </li>
        <li :class="{ active: page === pagination.page }" v-for="page in pages">
            <a href="javascript:void(0)"  @click="() => setPage(page)"><span>{{ page }}</span></a>
        </li>
        <li v-if="last_page < pagination.pages - 1">
            <a href="javascript:void(0)"><span>...</span></a>
        </li>
        <li v-if="last_page < pagination.pages - 1">
            <a href="javascript:void(0)"  @click="() => setPage(pagination.pages)">
                <span>
                    {{ pagination.pages }}
                </span>
            </a>
        </li>

        <li class="next" v-if="pagination.page < pagination.pages">
            <a href="javascript:void(0)" @click="() => setPage(pagination.page + 1)">&gt;</a>
        </li>
    </ul>
</template>
<script setup lang="ts">
import { Pagination } from '@/store/pagination';
import { computed } from 'vue';


interface Props {
    pagination: Pagination
    max_pages?: number
}

const props = withDefaults(defineProps<Props>(), {
    max_pages: 5
})
const emits = defineEmits<{
    (e: 'update', value: Pagination): void
}>()


const options = computed(() => {
    let first_page = props.pagination.page - props.max_pages
    if (first_page < 1) {
        first_page = 1
    }

    let last_page = first_page + props.max_pages * 2
    if (last_page > props.pagination.pages) {
        last_page = props.pagination.pages
        first_page = last_page - props.max_pages * 2
        if (first_page < 1) {
            first_page = 1
        }
    }
    return {
        first_page,
        last_page
    }
})

const first_page = computed(() => {
    return options.value.first_page
})

const last_page = computed(() => {
    return options.value.last_page
})

const pages = computed(() => {
    let pages = []
    for (let i = first_page.value; i <= last_page.value; i++) {
        pages.push(i)
    }
    return pages
})

const setPage = (page: number) => {
    let pagination = new Pagination()
    pagination.page = page
    pagination.rows = props.pagination.rows
    pagination.total = props.pagination.total
    emits('update', pagination)
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>